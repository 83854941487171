/* Primary Colors */

$color-white: #ffffff;
$color-black: #000000;
$color-charcoal: #212121;
$color-slate: #494949;
$color-dark-gray: #757575;
$color-light-gray: #cdcdcd;
$color-extra-light-gray: #e6e6e6;
$color-off-white: #f9f9f9;
$color-off-white-dark: #f0f0f0;
$color-success-green: #4a9232;

/* Secondary Colors */

$color-kohler-blue: #022e49;
$color-kohler-blue-loader: #345366;
$color-accent-blue-a: rgba(24, 104, 165, 0.1);
$color-accent-blue-b: rgba(24, 104, 165, 0.05);
$color-medium-gray: #989898;
$color-tile-bg: #f4f4f4;
$color-bright-gray: #efefef;

/* Error States */

$color-error-red: #d10000;
$text-dark-grey: #424242;
$text-medium-dark-grey: #5d5d5d;
$text-light-grey-cap: #6f6f6f;
$color-slate-light: #404040;
$cool-light-grey: #f7f7f7;

//== Font

$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size: 16px;
$font-height: 1.5;

//== Color

// Normal mode
$color-foreground: #202020;
$color-background: #ececec;
$color-link: #2020e0;

// Dark mode
$color-foreground-dark: invert($color-foreground);
$color-background-dark: invert($color-background);
$color-link-dark: invert($color-link);

$white: #ffffff;
$black: #000000;
$charcoal: #212121;
$slate: #494949;
$dark-gray: #757575;
$medium-gray: #989898;
$light-gray: #cdcdcd;
$extra-light-gray: #f1f1f1;
$white-smoke: #efefef;
$half-white: #f9f9f9;
$light-white: #e6e6e6;
$silver-white: #eeeeee;
$medium-light-gray: #cccccc;
$transparent: #ffffff00;
$dark-blue: #17181d;
$spanish-gray: #8f8f8f;
$teal: #00a3a3;
$light-teal: #00bce5;
$pale-blue: #3f6fb4;
$robin-blue: #00cccc;
$neon-blue: #4094ff;
$gray-3: #333333;
$eigen-blue: #121419;
$gray-4c: #4c4c4c;
$resene-gray: #595a5e;
$shadow-gray: #00000026;
$alice-blue: #f6f7f8;
$solitude-blue: #edeef1;
$soft-peach: #e8dfdf;
$haiti: #1c1b1f;
$ebony: #2d2f35;
$accent-blue: #e3ebf1;
$accent-gray-light: #eff2f5;
$primary-blue: #022e49;
$error-color: #d10000;
$extra-light-white: #fafafa;
$text-light-grey: #6f6f6f;
$blue: #1473e6;
$bg-light-gray: #f4f4f4;
$bg-white-smoke: #f5f5f5;
$success: #4a9232;

// Font family variable start here
$helvetica-light: "Helvetica Now Text W05 Light";
$helvetica-regular: "Helvetica Now Text W05 Regular";
$helvetica-medium: "Helvetica Now Text W05 Medium";
$helvetica-bold: "Helvetica Now Text W05 Bold";
// Font family variable End here
